<template>
  <div class="closed-page">
    <Hero
      :background-image="require('@/assets/image/hero-background-image.png')"
      heading="Bredbandswebben – tjänster för dig ansluten till Öppen Fiber från Telia"
      sub-heading="Med vårt öppna fibernät väljer du tjänsteleverantör och beställer själv bredband, tv och telefoni.">
      <div
        class="closed-page-hero-buttons">
        <MainButton
          expanded
          :disabled="loadingAddresses"
          label="Till företagstjänster"
          class="hero-button hero-button--business"
          type="secondary"
          @click="navigateToOffersPage(true)" />
        <MainButton
          expanded
          :disabled="loadingAddresses"
          class="hero-button hero-button--private"
          label="TIll privattjänster"
          type="secondary"
          @click="navigateToOffersPage" />
      </div>
    </Hero>

    <div class="container closed-page-container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h3 class="teasers-intro">
            Välkommen till Bredbandswebben
          </h3>
          <div>
            Bredbandswebben är sidan för dig som är ansluten till Öppen Fiber®.
            Öppen Fiber innebär att flera operatörer konkurrerar på lika villkor. Det skapar ett brett utbud av tjänster med
            bra prissättning och välkända varumärken
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Hero from '@/components/landing-page/Hero'
import MainButton from '@/components/MainButton'

import {
  mapGetters,
  mapState,
  mapActions
} from 'vuex'

import { RouteName } from '@/router/router-constants'

export default {
  components: {
    Hero,
    MainButton
  },
  computed: {
    ...mapGetters('BBWFlow', [
      'addressId',
      'companyId',
      'companyId',
      'punktId',
      'koId',
      'estateOwner',
      'checkSum'
    ]),
    ...mapState('AddressSearch', ['loadingAddresses'])
  },
  async created () {
    const payload = {
      addressId: this.addressId,
      companyId: this.companyId,
      punktId: this.punktId,
      koNumber: this.koId,
      estateOwner: this.estateOwner,
      checkSum: this.checkSum
    }

    await this.searchAddress(payload)
  },
  methods: {
    ...mapActions('AvailableOffers', [
      'searchOffersClosed',
      'searchSubscriptions'
    ]),
    ...mapActions('AddressSearch', ['searchAddress']),
    async navigateToOffersPage (isCorporate = false) {

      const payload = {
        addressId: this.addressId,
        companyId: this.companyId,
        punktId: this.punktId,
        koNumber: this.koId,
        estateOwner: this.estateOwner,
        checkSum: this.checkSum,
        isCorporate: isCorporate
      }

      this.searchOffersClosed(payload)
      this.searchSubscriptions(payload)

      this.$router.push({ name: RouteName.closed.AvailableOffers })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.closed-page {
  &-container {
    margin-top: 10rem;
    margin-bottom: 12rem;
  }

  &-hero-buttons {
    display: flex;
    justify-content: space-evenly;

    @media(max-width: $breakpoint-md) {
      flex-direction: column;
    }

    .hero-button {
      &--private {
        margin-left: 2.5rem;

        @media(max-width: $breakpoint-md) {
          margin-top: 1rem;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
