<template>
  <section
    :style="background"
    class="hero">
    <div class="hero-content">
      <h1>{{ heading }}</h1>
      <p class="teasers-intro">
        {{ subHeading }}
      </p>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    backgroundImage: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    subHeading: {
      type: String,
      required: true
    }
  },
  computed: {
    background () {
      return `background-image: url("${this.backgroundImage}")`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.hero {
  margin-bottom: 108px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 563px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;

  @media(max-width: $breakpoint-md) {
    margin-bottom: 154px;
    height: 337px;
    transition: all 0.5s ease-in-out;
  }

  @media(max-width: $breakpoint-sm) {
    margin-bottom: 206px;
    height: 226px;
    transition: all 0.5s ease-in-out;
  }

  .hero-content {
    background: $grey-100;
    margin: 0 12px -106px 0;
    padding: 64px 91px 48px 120px;
    max-height: 444px;
    min-width: 200px;
    width: 823px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-in-out;

    h1 {
      margin: 0;
    }

    p {
      margin: 24px 0;
      font-size: 22px;
      letter-spacing: 1.5px;
      line-height: 33px;
      font-weight: 400;
    }

    @media only screen and (max-width: $breakpoint-md) {
      margin: 0 12px -153px 0;
      padding: 48px 32px 48px 58px;
      width: 623px;
      transition: all 0.5s ease-in-out;

      h1 {
        font-size: 20pt;
      }

      p {
        margin: 16px 0 24px 0;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 22px;
      }
    }

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 0 12px -206px 0;
      padding: 32px 16px 32px 26px;
      transition: all 0.5s ease-in-out;

      h1 {
        line-height: 30px;
        letter-spacing: 0.5px;
      }

      p {
        margin: 8px 0 24px 0;
      }
    }
  }
}
</style>
